module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Real Estate Shows","short_name":"RES","start_url":"/","background_color":"#5f29c7","theme_color":"#5f29c7","display":"minimal-ui","icon":"src/images/icon.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-9897813-4"},
    },{
      plugin: require('../plugins/facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"709392139892150"},
    },{
      plugin: require('../plugins/intercom/gatsby-browser.js'),
      options: {"plugins":[],"app_id":"ifbsj2u2"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
