import React, { createContext, useContext } from 'react'
import PropTypes from 'prop-types'
import { useToggle } from '../../hooks'

export const AppContext = createContext({
  isNavOpen: false,
  isHeaderCtaVisible: true,
})

export const PageWrapper = ({ children }) => {
  const [isNavOpen, setIsNavOpen, toggleNav] = useToggle(false)
  const [isHeaderCtaVisible, setHeaderCta] = useToggle(true)

  return (
    <AppContext.Provider
      value={{
        isNavOpen,
        setIsNavOpen,
        toggleNav,
        isHeaderCtaVisible,
        setHeaderCta,
      }}
    >
      {children}
    </AppContext.Provider>
  )
}

PageWrapper.propTypes = {
  children: PropTypes.node,
}

export const useAppState = () => useContext(AppContext)
