exports.onRouteUpdate = ({ prevLocation }) => {
  // Prod only
  if (process.env.NODE_ENV !== `production`) {
    return null
  }

  // Bail if fbq isn't defined
  if (typeof fbq !== `function`) {
    return null
  }

  // Bail on intial app load. This is handled on the ssr script
  if (null === prevLocation) {
    return null
  }

  // Wrap inside a timeout to make sure react-helmet is done with it's changes (https://github.com/gatsbyjs/gatsby/issues/9139)
  const sendPageView = () => {
    // eslint-disable-next-line no-undef
    fbq(`track`, `PageView`)
  }

  setTimeout(sendPageView, 500)
}
