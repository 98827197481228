import React from 'react'

import { PageWrapper } from './src/components/state'
import './src/css/fonts.css'
import './src/css/style.css'

// eslint-disable-next-line react/prop-types
export const wrapRootElement = ({ element }) => (
  <PageWrapper>{element}</PageWrapper>
)
