// Check to see if enabled to prevent JS errors
const isEnabled = () => typeof Intercom === `function` && window.IntercomAppId

exports.onInitialClientRender = () => {
  if (!isEnabled()) {
    return
  }

  window.Intercom('boot', {
    app_id: window.IntercomAppId,
  })
}

exports.onRouteUpdate = () => {
  if (!isEnabled()) {
    return
  }

  window.Intercom('update')
}
